import { ErrorHandler, Injectable } from "@angular/core";
import { TrackJS } from "trackjs";

@Injectable()
export class TrackJsErrorHandler implements ErrorHandler {
    handleError(error:any) {
        // Add the error message to the telemetry timeline.
        // It can occasionally have useful additional context.
        console.warn(error.message);

        // Assumes we have already loaded and configured TrackJS*
        TrackJS.track(error.originalError || error);
    }
}