import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { finalize, Observable } from "rxjs";
import { LoadingService } from "./loading.service";

@Injectable()
export class NetworkInterceptor implements HttpInterceptor {
  constructor(private loader: NgxUiLoaderService) {}

  static networkLoaderId = "network";

  intercept(
request: HttpRequest<unknown>,
next: HttpHandler
): Observable<HttpEvent<unknown>> {
  const skipLoading = request.url.endsWith("/ping")

  let loadingId = setTimeout(() => {
    if (!skipLoading) {
      this.loader.start(NetworkInterceptor.networkLoaderId)
    }
  },150)

    // this.loader.showLoading();
    return next.handle(request).pipe(
      finalize(() => {
       if (!skipLoading) {
         clearTimeout(loadingId);
         this.loader.stop(NetworkInterceptor.networkLoaderId)
        // this.loader.hideLoading();
       }
      })
    );
  }
}