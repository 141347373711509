import { Injectable } from '@angular/core';

import { environment } from './../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService implements Config {
  private _config: any = environment.config;

  constructor() {}
  get dataApiUrl(): string {
    return this._config.DATA_API_URL;
  }

  get googleSignInClientId(): string {
      return this._config.GOOGLE_SIGNIN_CLIENT_ID;
  }

  get(key: any) {
    return this._config[key];
  }
}

export interface Config {
  get dataApiUrl(): string;
  get googleSignInClientId(): string;
}