import { DATE_PIPE_DEFAULT_TIMEZONE } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Trip } from '../trip/trip';
import { DateTime } from 'luxon'

@Component({
  selector: 'app-trip-select',
  templateUrl: './trip-select.component.html',
  styleUrls: ['./trip-select.component.scss']
})
export class TripSelectComponent {

  constructor() { }

  private _trips: Trip[] = [];

  get trips(): Trip[] {
    return this._trips
  }

  @Input()
  set trips(trips) {

    if (trips?.length > 0) {

      const currentTrips = trips.filter((t) => {
        const tripEnd = DateTime.fromISO(t.tripDate).setZone('local', {keepLocalTime: true}).plus({ days: t.duration - 1 }).endOf("day")
        const tripStart = DateTime.fromISO(t.tripDate).setZone('local', {keepLocalTime: true})

        return tripEnd > DateTime.now() &&
          tripStart < DateTime.now()
      })

      this._trips = currentTrips;

      if (currentTrips.length === 1) {
        this.selectedTripId = currentTrips[0].id
        this.selectedTripEvent.emit(currentTrips[0])
      }
 
     
    }
    else {
      this._trips = []
    }



  }

  @Output()
  selectedTripEvent = new EventEmitter<Trip>();


  selectedTripId: number

  get selectedTrip() {
    if (this.trips) {
      return this.trips.find((t) => t.id === this.selectedTripId)
    }
    else {
      return undefined
    }

  }


  tripSelectionChanged(event) {
    if (event.isUserInput) {
      this.selectedTripEvent.emit(event.source.value);
    }
  }

  hasTrips() {
    return this._trips && this._trips.length > 0
  }

}
