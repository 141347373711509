import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject, Subject } from 'rxjs';
@Injectable()
export class PwaService {
    promptEvent

    updateAvailable: BehaviorSubject<boolean>

    private _updateAvailable: boolean = false;

    public get versionUpdates() {
        return this.swUpdate.versionUpdates
    }

    constructor(private swUpdate: SwUpdate) {
        window.addEventListener('beforeinstallprompt', event => {
            this.promptEvent = event;
        });

        this.updateAvailable = new BehaviorSubject<boolean>(this._updateAvailable);

        if (this.swUpdate.isEnabled) {

            this.swUpdate.unrecoverable.subscribe((event) => {
                console.error("Unrecoverable error.  Reloading application", event.reason);
                window.location.reload()
            })

            this.swUpdate.versionUpdates.subscribe((event) => {
                if (event.type === "VERSION_READY") {
                    this._updateAvailable = true;
                    this.updateAvailable.next(this._updateAvailable)
                }

            })

            setInterval(async () => {
                try {

                    await this.swUpdate.checkForUpdate();
                }
                catch (error) {
                    console.log(error)
                }
            }, 10000)
        }
        else {
            console.log("Browser does not support pwa")
        }
    }

    get pwaAvailable(): boolean {
        return this.swUpdate.isEnabled
    }

    checkForUpdate() {
        this.swUpdate.checkForUpdate()
    }

    async activateUpdate() {
        if (await this.swUpdate.activateUpdate()) {
            window.location.reload();
        }
    }


}