import { DatabaseService } from "../db/database.service";
import Dexie from "dexie";
import { Trip } from "./trip";
import { Injectable } from "@angular/core";


@Injectable()
export class LocalTripService {
    table: Dexie.Table<Trip, number>;

    constructor(private databaseService: DatabaseService) {
        this.table = databaseService.table("trips")
    }

    getAll() {
        return this.table.toArray();
    }

    add(trip: Trip) {
        this.table.add(trip);
    }

    update(id: number, changes: Partial<Trip>) {
        this.table.update(id, changes);
    }

    put(trip: Trip) {
        this.table.put(trip);
    }
}