
 <div class="page-wrap">
<mat-toolbar color="primary" class="expanded-toolbar">
  <span class="home-text">
 <button mat-button routerLink="/">Team Check In</button>
  </span>
  <div fxLayout="row"  *ngIf="!login_page">
    <button class="btn-upload" mat-button [disabled]="(networkStatus$ | async) === false" (click)="syncData()" *ngIf="(pendingUploadsCount$ | async) > 0">
      <mat-icon *ngIf="networkStatus$ | async; else offline" [matBadge]="pendingUploadsCount$ | async" matBadgeColor="accent">cloud_upload</mat-icon>
      <ng-template #offline>
        <mat-icon>cloud_off</mat-icon>
      </ng-template>
    </button>

  </div>
</mat-toolbar>
<mat-toolbar color="accent" class="bottom-toolbar">
  <span class="home-text">
    <img src="./assets/PPM-logo-white-block-letters.png" width="230" height="80" class="logo">
  </span>
  <div fxLayout="row" >
    <button mat-stroked-button class="install" *ngIf="Pwa.promptEvent" (click)="installPwa()">
      INSTALL
    </button>
    <button mat-stroked-button class="update" *ngIf="Pwa.updateAvailable | async" (click)="updatePwa()">
      UPDATE
    </button>
  </div>
</mat-toolbar>
<ngx-ui-loader></ngx-ui-loader>
<mat-progress-bar mode="indeterminate" *ngIf="loadingService.loadingSubject | async"></mat-progress-bar>
<main class="content">
<router-outlet ></router-outlet>
</main>
<mat-toolbar class="footer" color="accent"><a mat-button target="_blank" href="https://docs.google.com/document/d/1HgW0fRk0_ksgmdW2GSu1BdYBz_qNzXtwGQH23PJUOUU">App Overview</a></mat-toolbar>
</div>