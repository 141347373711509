import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class LoadingService {

    showCount: number;

    loadingSubject: Subject<boolean>;

    constructor() {
        this.loadingSubject = new Subject<boolean>();
        this.showCount = 0;
    }

    showLoading() {
        if (this.showCount === 0) {
            this.loadingSubject.next(true);
        }

        this.showCount++;
    }

    hideLoading(forceClose = false) {

        if (forceClose === true) {
            this.showCount = 1;
        }

        if (this.showCount > 0) {
            this.showCount--
            if (this.showCount === 0) {
                this.loadingSubject.next(false)
            }
        }

    }
}