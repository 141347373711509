import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpRequest,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { LocalAuthService } from './local-auth.service';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ConfigService } from '../config.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(
    private router: Router,
    public auth: LocalAuthService,
    private configService: ConfigService
  ) { }

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    if (request.url.match(`${this.configService.dataApiUrl}`)) {
      request = this.addTokenHeader(request, this.auth.authToken);


      return next.handle(request).pipe(catchError(error => {
        if (error instanceof HttpErrorResponse && !request.url.includes("refresh") && !request.url.includes("google-oauth") && error.status === 401) {
          return this.handle401Error(request, next)
        }
        return throwError(error);
      }));
    }
    else {
      return next.handle(request);
    }

  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
        'mcapi-key': "PLTxAQ5jS7qTSdKQf71nUCs4JKzeFNRVeqbKrQ8EFV1ESGTIi9ch6rZAW3XI0Nea"
      },
    })
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {

      this.refreshTokenSubject.next(null);

      if (this.auth.loggedIn) {

        try {
          this.isRefreshing = true;
          return this.auth.refreshLogin().pipe(
            switchMap((token: any) => {
              this.isRefreshing = false;

              this.auth.setToken(token)

              this.refreshTokenSubject.next(token.accessToken);

              return next.handle(this.addTokenHeader(request, token.accessToken));
            }),
            catchError((err) => {
              this.isRefreshing = false;

              this.auth.clearToken();
              this.router.navigate(['/login'], { queryParams: { session: 'expired' } });
              console.log(err);
              return throwError(() => new Error("Token refresh failed"));
            })
          );
        }
        catch (err) {
          this.isRefreshing = false;
        }
      }
    }
  }
}