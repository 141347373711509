import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Observable } from 'rxjs';
import { ApiService, DataResult } from '../api.service';
import { ConfigService } from '../config.service';
import { LocalHeadCount } from '../db/local-head-count';
import { HeadCount } from './head-count';

@Injectable({
  providedIn: 'root'
})
export class HeadCountsService {

  constructor(
    private apiService: ApiService,
    private configService: ConfigService
  ) {

  }

  getCountsForTrip(tripId: number): Observable<HeadCount[]> {
    const params = new HttpParams().set("filter", JSON.stringify(
      {
        include: [{ relation: "headCountMembers" }]
      }
    ));
    return this.apiService.callApi<HeadCount[]>(`${this.configService.dataApiUrl}/head-counts/trip/${tripId}`, "GET", params);
  }

  getCountById(countId: number): Observable<HeadCount> {
    const params = new HttpParams().set("filter", JSON.stringify(
      {
        include: [{ relation: "headCountMembers" }]
      }
    ));
    return this.apiService.callApi<HeadCount>(`${this.configService.dataApiUrl}/head-counts/${countId}`, "GET", params);
  }

  newCount(count: LocalHeadCount) {
    const uploadObj = Object.assign({}, count)
    if (Object.prototype.hasOwnProperty.call(uploadObj, "id")) delete uploadObj.id;

    //JSON parser can't handle undefined
    if (uploadObj.tlSignature === undefined) {
      uploadObj.tlSignature = "";
    }

    if (uploadObj.glSignature === undefined) {
      uploadObj.glSignature = "";
    }

    return this.apiService.callApi<HeadCount>(`${this.configService.dataApiUrl}/head-counts`, "POST", JSON.stringify(uploadObj))
  }

  getSignatureAgreement() {
    return this.apiService.callApi<DataResult<string>>(`${this.configService.dataApiUrl}/head-counts/signature-agreement`, "GET")

  }

}
