<h2>Counts</h2>
<mat-card appearance="outlined">
  <mat-card-content>

    <div fxLayout="row" >
      <app-trip-select [trips]="trips$ | async" (selectedTripEvent)="onTripSelected($event)">
      </app-trip-select><button mat-icon-button (click)="refreshTrips()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
    <h4 *ngIf="selectedTrip">{{selectedTrip.groupName}} - {{selectedTrip.location}} - {{selectedTrip.tripDate | date}}</h4>
    <div fxLayout="row"><button *ngIf="selectedTrip" mat-stroked-button color="primary" (click)="onNewClicked()">New Count</button></div>
    <p *ngIf="!selectedTrip">Please select a trip to begin.</p>
    <p *ngIf="!hasCounts() && selectedTrip">No counts have been made yet.  Select "New Count" to start your initial team Check-in.</p>
    <table *ngIf="hasCounts()"  mat-table [dataSource]="headCountDataSource">
      <ng-container matColumnDef="countDate">
        <th mat-header-cell *matHeaderCellDef> Date </th>
        <td mat-cell *matCellDef="let headCount"> {{headCount.countDate | date}} <span *ngIf="!headCount.staffId">(not synced)</span> </td>
      </ng-container>

      <ng-container matColumnDef="count">
        <th mat-header-cell *matHeaderCellDef>Count</th>
        <td mat-cell *matCellDef="let headCount"> {{headCount.headCountMembers.length}}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr mat-row *matRowDef="let row; columns: columnsToDisplay;" (click)="onCountClicked(row)"></tr>

    </table>
  </mat-card-content>
</mat-card>
