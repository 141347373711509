 <div class="mat-elevation-z8">
  <h2>Trip Participants</h2>
  <p class="trip">{{ selectedTrip.tripDate | date }} - {{selectedTrip.location}} - {{selectedTrip.groupName}}</p>
    <div class="header">

      <a mat-raised-button color="basic" [routerLink]="['/trips']">
        Back
      </a>
    </div>
    <div>
      <mat-form-field class="example-full-width">
        <span matPrefix><mat-icon class="search">search</mat-icon> &nbsp;</span>
        <input type="text" #searchFilter (keyup)="applyFilter(searchFilter.value)" matInput placeholder="Search">
      </mat-form-field>
    </div>
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <table *ngIf="!loading" mat-table [dataSource]="dataSource" matSort>

      <!-- Position Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let element" (click)="onSurveyClicked(element)"> {{getFullname(element)}} </td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="screen">
        <th mat-header-cell *matHeaderCellDef width="100px"></th>
        <td mat-cell *matCellDef="let element">
          <img src="/assets/check.png" class="check" *ngIf="!checkSurveyStatus(element)">
          <img src="/assets/check-full.png" class="check" *ngIf="checkSurveyStatus(element)">
        </td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef width="50px"> </th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button (click)="onSurveyClicked(element)">
            <mat-icon aria-label="Example icon-button with a heart icon">question_answer</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
