import { Component, OnInit, EventEmitter, AfterContentInit, OnDestroy } from '@angular/core';
import { LocalAuthService } from "../auth/local-auth.service";
import { ActivatedRoute, Router } from '@angular/router';
import { Network } from '@ngx-pwa/offline';
import { ParticipantsService } from "../participants/participants.service";
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PwaService } from "../pwa.service"
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalHeadCountService } from '../db/local-head-count.service';
import { throwToolbarMixedModesError } from '@angular/material/toolbar';
import { distinctUntilChanged, Observable, Subscription } from 'rxjs';
import { LoadingService } from '../utility/loading.service';
import { NavigationService } from './navigation.service';
import { NetworkService } from '../utility/network.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',

  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, AfterContentInit, OnDestroy {

  networkStatus$: Observable<boolean>;

  isAuthenticated: boolean;
  surveys = [];
  login_page = false;
  n_count = 0;
  pendingUploadsCount$: Observable<number>;
  networkStatusSubscription: Subscription;
  constructor(
    private authService: LocalAuthService,
    private router: Router,
    protected network: Network,
    private participantsService: ParticipantsService,
    private uiLoadingService: NgxUiLoaderService,
    private snackBar: MatSnackBar,
    public Pwa: PwaService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private localHeadCountService: LocalHeadCountService,
    public loadingService: LoadingService,
    public navigationService: NavigationService,
    private networkService: NetworkService
  ) {
    //this.networkStatus$ = this.network.onlineChanges;
    this.networkStatus$ = this.networkService.networkStatus;
  }


  ngOnDestroy(): void {
    this.networkStatusSubscription.unsubscribe()
  }
  ngAfterContentInit(): void {
    this.localHeadCountService.triggerCountUpdate();
  }

  ngOnInit() {


    this.checkLoginPage()


    this.pendingUploadsCount$ = new Observable<number>((subscriber) => {
      this.localHeadCountService.changed$.subscribe(() => {

        this.localHeadCountService.getCount().then(
          (result) => {
            subscriber.next(result);
          },
          (error) => subscriber.next(0)
        )
      })
    })


    if (this.Pwa.pwaAvailable) {
      this.pendingUploadsCount$.pipe(distinctUntilChanged()).subscribe((count) => (navigator as any).setAppBadge(count))
    }

    this.networkStatusSubscription = this.networkStatus$.subscribe((status) => {
      console.log("Network status: " + status)
      if (status === true) {
        this.proceedSync();
      }
    })

  }



  logout() {
    this.authService.logout();
    this.router.navigate(["/login"]);
  }

  installPwa(): void {
    this.Pwa.promptEvent.prompt();
  }

  updatePwa(): void {
    this.loadingService.showLoading();
    this.Pwa.activateUpdate()
    //no need to stop loading.  page will reload.
  }

  checkLoginPage() {
    if (localStorage.getItem('token')) {
      this.login_page = false;
    } else {
      this.login_page = true;
    }
    setTimeout(() => {
      this.checkLoginPage();
    }, 2000);
  }
  async syncData() {
    const dialogRef = this.dialog.open(ConfrimSyncDialogComponent, {
      width: '250px',
      data: {}
    });

    dialogRef.componentInstance.onProceed.subscribe(async () => {
      await this.proceedSync(true);
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("dialog closed")
    });
  }
  async proceedSync(manualTrigger: boolean = false) {
    try {

      this.uiLoadingService.start();

      const recordsUploaded = await this.localHeadCountService.synchronize();

      if (recordsUploaded > 0 || manualTrigger) {
        this.snackBar.open(`Uploaded ${recordsUploaded} records`, null, {
          duration: 5000
        });
      }

    } catch (error) {
      this.snackBar.open("There was a problem syncing data.  Please try again later.");
      console.error(error);
    } finally {
      this.uiLoadingService.stopAll();
    }


  }
  async updateStorageCount() {
    this.n_count = 0;

    this.n_count = await this.localHeadCountService.getCount();
    console.log(`count: ${this.n_count}`);
  }
}

@Component({
  selector: 'app-sync-confirm-dialog',
  templateUrl: 'confirm-sync-dialog.component.html',
})
export class ConfrimSyncDialogComponent {

  onProceed = new EventEmitter();
  constructor(
    public dialogRef: MatDialogRef<ConfrimSyncDialogComponent>
  ) {

  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  proceed() {
    this.onProceed.emit();
  }
}