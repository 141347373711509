import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom, Observable, Subject } from 'rxjs';
import { ApiService } from '../api.service';
import { ConfigService } from '../config.service';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  networkStatus: BehaviorSubject<boolean>
  private currentStatus = false;

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService,
    private apiService: ApiService
    ) {
    
      this.networkStatus = new BehaviorSubject<boolean>(false)
   
      //check as soon as possible, then every 10 seconds
      setTimeout(async () => {
        this.currentStatus = await this.checkConnectivity();
        this.networkStatus.next(this.currentStatus);
        const id = setInterval(async () => {
          const newStatus = await this.checkConnectivity()

          if (newStatus !== this.currentStatus) {
            this.currentStatus = newStatus
            this.networkStatus.next( newStatus)

          }
           
         }, 10000);
      })
      
    
   }

   public get currentNetworkStatus() {
     return this.currentStatus;
   }

   private async checkConnectivity() {
    const url = this.configService.get('DATA_API_URL') + "/ping"
    
    try {
      await firstValueFrom(this.apiService.callApi<any>(url))
      return true
    }
    catch (error) {
      return false
    }


    // this.httpClient.get(url).subscribe({
    //   next: () => {
    //   return true;
    //   },
    //   error: () => {
    //    return false
    //   }
    // })
   }



}
