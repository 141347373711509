import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';
import { LocalAuthService } from "../auth/local-auth.service"
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { SocialAuthService, GoogleLoginProvider } from "@abacritt/angularx-social-login";
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  form: UntypedFormGroup;
  public loginInvalid: boolean;
  private formSubmitAttempt: boolean;
  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private storage: StorageMap,
    private authService: LocalAuthService,
    private ngxService: NgxUiLoaderService,
    private socialLoginService: SocialAuthService
  ) { }

  ngOnInit() {

    if(this.authService.loggedIn){
      this.router.navigate(["/counts"]);
    }

    this.form = this.fb.group({
      username: ['', Validators.email],
      password: ['', Validators.required]
    });

    this.socialLoginService.authState.subscribe((user) => {

      this.ngxService.start();
        
      try {
        this.authService.login({type: 'google', token: user.idToken});
        this.ngxService.stop();
      }
      catch (err) {
        this.loginInvalid = true;
        this.ngxService.stop();
      }

    });

  }

  async onSubmit() {
    this.loginInvalid = false;
    this.formSubmitAttempt = false;
    if (this.form.valid) {
      this.ngxService.start();
      try {
        const username = this.form.get('username').value;
        const password = this.form.get('password').value;
        let login = await this.authService.login({type: 'local', username: username, password: password});
        this.ngxService.stop();
        console.log("login",login);
      } catch (err) {
        this.loginInvalid = true;
        this.ngxService.stop();
      }
    } else {
      this.formSubmitAttempt = true;
    }
  }

}
