import { Injectable } from "@angular/core";
import Dexie from "dexie";

@Injectable()
export class DatabaseService extends Dexie {
   

    constructor() {
        super("TciDatabase");

        this.version(6).stores({
            participants: "id",
            trips: "id",
            headCounts: "id++"
        })
    }
}


