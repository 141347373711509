<mat-card appearance="outlined">
  <mat-card-title>
    <h2>New Team Count</h2>
  </mat-card-title>
  <mat-card-content fxLayout="column" fxLayoutGap="10px">

    <form fxFlex="33">
      <mat-selection-list name="participants" [(ngModel)]="selectedParticipants">
        <mat-list-option *ngFor="let participant of participants" [value]="participant" checkboxPosition="before">
          {{participant.name}}
        </mat-list-option>
      </mat-selection-list>
      <mat-error *ngIf="!selectedParticipantsValid()">At least one participant must be selected</mat-error>
    </form>

    <mat-divider fxFlex></mat-divider>

    <button *ngIf="!showSignatures()" mat-flat-button color="primary" (click)="toggleSignatureCollection()" >Add Signatures</button>
    <button *ngIf="!isFirstCount && showSignatures()" mat-flat-button color="primary" (click)="toggleSignatureCollection()" >Remove Signatures</button>
    <form fxLayout="column" fxLayoutGap="10px" *ngIf="showSignatures()">
      <div fxLayout="row" fxLayoutGap="5px">
        <button fxFlex="66" mat-stroked-button (click)="getTlSignature()">TL Signature</button>


        <mat-checkbox fxFlex="33" [checked]="tlSignatureValid()" (click)="$event.preventDefault()"
          [class.mat-checkbox-disabled]="false"></mat-checkbox>


      </div>
      <mat-error *ngIf="!tlSignatureValid()">TL Signature is required</mat-error>

      <div fxLayout>
        <img fxFlex="66" *ngIf="tlSignatureDataUrl" [src]="tlSignatureDataUrl" />
      </div>
      <div *ngIf="leaders.length > 0" fxLayout="row" fxLayoutGap="5px">
        
        <mat-form-field>
          <mat-select placeholder="Select a Leader" [value]="selectedLeader">
            <mat-option *ngFor="let leader of leaders" [value]="leader" (onSelectionChange)="leaderSelectionChanged($event)" >
              {{ leader.firstname }} {{leader.lastname }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div *ngIf="leaders.length === 0"  >
        There are no leaders on the trip roster.  Please mark at least one leader (GL or Lead) as Absolutely on the trip roster.
      </div>
      <div *ngIf="selectedLeader" fxLayout="row" fxLayoutGap="5px">
        <button fxFlex="66" mat-stroked-button (click)="getGlSignature()">GL Signature</button>
        <mat-checkbox fxFlex="33" [checked]="glSignatureValid()" (click)="$event.preventDefault()"
          [class.mat-checkbox-disabled]="false"></mat-checkbox>
      </div>
      <mat-error *ngIf="!glSignatureValid()">GL Signature is required</mat-error>

      <div fxLayout>
        <img fxFlex="66" *ngIf="glSignatureDataUrl" [src]="glSignatureDataUrl" />
      </div>
    </form>
    <mat-divider inset></mat-divider>
    <mat-card-actions>
      <button mat-flat-button color="primary" [disabled]="!formIsValid()" (click)="saveCount()">Save</button>
    </mat-card-actions>

  </mat-card-content>

</mat-card>
