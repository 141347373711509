import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';

import { ParticipantsComponent } from './participants/participants.component';

import { AuthGuard } from './auth/auth-guard.service';
import { AccessGuard } from './auth/access-guard.service';
import { HeadCountsComponent } from './head-counts/head-counts.component';
import { HeadCountComponent } from './head-counts/head-count/head-count.component';
import { NewHeadCountComponent } from './head-counts/new-head-count/new-head-count.component';

const routes: Routes = [
  {
    path: '',
    component: HeadCountsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'counts',
    component: HeadCountsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'counts/:tripId',
    component: HeadCountsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'count/:countId',
    component: HeadCountComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'count',
    component: HeadCountComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'trip/:tripId/new-count',
    component: NewHeadCountComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'participants/:tripid',
    component: ParticipantsComponent,
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes/*,{ useHash: true }*/)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
