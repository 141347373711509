import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Network } from '@ngx-pwa/offline';
import SignaturePad from 'signature_pad';
import { LocalAuthService } from 'src/app/auth/local-auth.service';
import { LocalHeadCount } from 'src/app/db/local-head-count';
import { LocalHeadCountService } from 'src/app/db/local-head-count.service';
import { Participant } from 'src/app/participants/participants';
import { ParticipantsService } from 'src/app/participants/participants.service';
import { SignatureDialogData, SignatureDialogResult, SignaturePadDialogComponent } from 'src/app/signature-pad/signature-pad-dialog.component';
import { TripService } from 'src/app/trip/trip.service';
import { NetworkService } from 'src/app/utility/network.service';
import { HeadCountsService } from '../head-counts.service';
import { ParticipantRemovalDialogComponent, ParticipantRemovalDialogData, ParticipantRemovalDialogResult, RemoveParticipantData } from './participant-removal-dialog/participant-removal-dialog.component';

class NewCountViewModel {
  name: string;
  id: number
}

@Component({
  selector: 'app-new-head-count',
  templateUrl: './new-head-count.component.html',
  styleUrls: ['./new-head-count.component.scss']
})
export class NewHeadCountComponent implements OnInit {

  constructor(
    private tripService: TripService,
    private participantService: ParticipantsService,
    private headCountService: HeadCountsService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: LocalAuthService,
    private localHeadCountService: LocalHeadCountService,
    private network: Network,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private networkService: NetworkService
  ) { 

    const nav = this.router.getCurrentNavigation();

    if (nav?.extras?.state?.headCountCount !== undefined) {
      this.headCountCount = nav.extras.state.headCountCount;
    }
    else {
      this.router.navigate(["counts"]);
    }

  }

  headCountCount: number;
  participants: NewCountViewModel[];
  selectedParticipants: NewCountViewModel[];
  selectedLeader: Participant;
  leaders: Participant[] = [];
  tripId: number;
  removeUncountedParticipants: boolean = true;
  signatureAgreement: string;
  forceSignatures = false;


  tlSignatureDataUrl: string;
  glSignatureDataUrl: string;


  ngOnInit(): void {


    this.tripId = Number.parseInt(this.route.snapshot.paramMap.get("tripId"));

    this.participantService.getParticipants(this.tripId).subscribe(
      (participants) => {

        this.leaders = participants.filter(p => p.rolegl === 1 || p.rolelead === 1);
        this.participants = participants.map(p => {
          return {
            name: [p.firstname, p.middlename, p.lastname].join(" "),
            id: p.newtripUserId
          }
        });

        if (this.leaders.length === 1) {
          this.selectedLeader = this.leaders[0];
        }

      }
    );

    this.headCountService.getSignatureAgreement().subscribe(
      (agreement) => {this.signatureAgreement = agreement.data}
    );

  }

  async saveCount(confirmed?: boolean, participantsToRemove: RemoveParticipantData[] = []) {
    let addedCount = 0;

    if (this.formIsValid()) {

      console.log(`confirmed: ${confirmed} headcountcount: ${this.headCountCount}, partlen: ${this.participants.length} selpartlen ${this.selectedParticipants.length}`)
      if (!confirmed && this.participants.length !== this.selectedParticipants.length && this.headCountCount === 0) {
        let removalCandidates: RemoveParticipantData[] = [];

        for (const participant of this.participants) {
          if (!this.selectedParticipants.find((p) => p.id === participant.id)) {
            removalCandidates = [...removalCandidates, participant]
          }
        }
        const dialogRef = this.dialog.open<ParticipantRemovalDialogComponent, ParticipantRemovalDialogData, ParticipantRemovalDialogResult>(ParticipantRemovalDialogComponent, { maxWidth: "95vw", data: { removalCandidates: removalCandidates } });

        dialogRef.afterClosed().subscribe(result => {
          if (result.confirmed) {

            this.saveCount(true, result.participantsToRemove);
          }
        });

      }
      else {
        confirmed = true;
      }

      if (confirmed) {
        try {

          if (!this.showSignatures()) {
            this.selectedLeader = this.leaders[0]; //if no signatures are required, leader doesn't matter, but can't send null.
          }

          const newCount: LocalHeadCount = {
            countDate: new Date(),
            tripId: this.tripId,
            groupLeaderId: this.selectedLeader.newtripUserId,
            tlSignature: this.tlSignatureDataUrl,
            glSignature: this.glSignatureDataUrl,
            headCountMembers: this.selectedParticipants.map((p) => { return { headCountId: 0, newtripUserId: p.id } }),
            participantsToRemove: participantsToRemove.map((p) => p.id)
          }
          addedCount = await this.localHeadCountService.add(newCount)
          newCount.id = addedCount;

          if (this.networkService.currentNetworkStatus) {
            await this.localHeadCountService.synchronize();
            
            this.router.navigate(["/counts"],  {state: {message: "Count successfuly uploaded"} });
            //this.snackBar.open("Count successfuly uploaded", null, { duration: 5000 })
          }
          else {
          
            this.router.navigate(["/counts"],  {state: {message: "You are currently offline.  The count will be uploaded when you are back online."} });
            //this.snackBar.open("You are currently offline.  The count will be uploaded when you are back online.", null, { duration: 5000 })
          }
         


        } catch (error) {
          if (addedCount === 0) {
            console.error(error);
            this.snackBar.open("There was a problem saving.  Please try again", null, { duration: 5000 });
          }
          else {
            this.router.navigate(["/counts"],  {state: {message: "The count was saved, but the upload failed.  The upload will be retried on the next sync."} });
          }
        }
      }
    }
  }

  toggleSignatureCollection() {
    this.forceSignatures = !this.forceSignatures

    if (!this.forceSignatures) {
      this.tlSignatureDataUrl = undefined
      this.glSignatureDataUrl = undefined
    }
  }

  getTlSignature() {
    const dialogRef = this.dialog.open<SignaturePadDialogComponent, SignatureDialogData, SignatureDialogResult>(SignaturePadDialogComponent, { maxWidth: "100vw", panelClass: "signature-dialog-container", data: {prompt: this.signatureAgreement} });

    dialogRef.afterClosed().subscribe(result => {
      if (result?.isValid) {
        this.tlSignatureDataUrl = result.dataUrl;
      }
    });
  }

  tlSignatureValid() {
    return (this.tlSignatureDataUrl && this.tlSignatureDataUrl.length > 0) || !this.showSignatures()
  }

  getGlSignature() {
    const dialogRef = this.dialog.open<SignaturePadDialogComponent, SignatureDialogData, SignatureDialogResult>(SignaturePadDialogComponent,{ maxWidth: "100vw", data: {prompt: this.signatureAgreement} } );

    dialogRef.afterClosed().subscribe(result => {
      if (result?.isValid) {
        this.glSignatureDataUrl = result.dataUrl;
      }
    });
  }

  glSignatureValid() {
    return (this.glSignatureDataUrl && this.glSignatureDataUrl.length > 0) || !this.showSignatures()
  }

  showSignatures() { 
    return this.isFirstCount || this.forceSignatures;
  }

  get isFirstCount() {
    return this.headCountCount === 0
  }

  selectedParticipantsValid() {
    return (this.selectedParticipants && this.selectedParticipants.length > 0);
  }

  formIsValid() {
    return (this.glSignatureValid() && this.tlSignatureValid() && this.selectedParticipantsValid())
  }

  leaderSelectionChanged(event: MatOptionSelectionChange ) {
    if (event.isUserInput) {
      this.glSignatureDataUrl = "";
      this.selectedLeader = event.source.value;
    }
  }

}
