import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

import { Observable, of, firstValueFrom, concat, throwError } from 'rxjs';
import { catchError, retry, mergeMap } from 'rxjs/operators';

import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalAuthService } from './auth/local-auth.service';
import { Router } from '@angular/router';

export interface DataResult<T> {
  data: T;
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private snackBarDuration: number = 2000;

  constructor(private httpClient: HttpClient, 
    public snackBar: MatSnackBar, 
    public authService: LocalAuthService,
    public router: Router,) { }

  callApi<T>(
    url: string,
    method: string = 'GET',
    body: any = {},
    headers: HttpHeaders = new HttpHeaders()
  ): Observable<T> {
    
    let self = this;
    let refreshed = false;

    // headers = headers
    //   .set("Authorization", "Bearer " + this.authService.authToken)
    //   .set("Content-Type", "application/json");


    let returnObservable: Observable<T>;


    switch (method) {
      case 'GET': {
        returnObservable = this.httpClient
          .get<T>(url, { headers: headers, params: body })

      }
      break;
      case 'POST': {
        returnObservable = this.httpClient
          .post<T>(url, body, { headers: headers })

      }
        break;
      case 'PUT': {
        returnObservable = this.httpClient
          .put<T>(url, body, { headers: headers })

      }
        break;
      case 'DELETE': {
        returnObservable = this.httpClient
          .delete<T>(url, { headers: headers })

      }
        break;
      default: {
        throw new Error('Method not implemented');

      }
        break;
    }


    return returnObservable;

  }

  private handleError<T>(url = null, result?: T) {
    return (error: any): Observable<T> => {
      console.log("error", error)
      this.log(`${url} failed: ${error.status}`);

      let snackBarRef = this.snackBar.open("Cannot get results from the server or cache", null, {
        duration: this.snackBarDuration
      });

      return of(result as T);
    };
  }

  private log(message: string) {
    console.log('[Api Service]: ' + message);
  }
}
