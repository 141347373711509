import { Injectable } from '@angular/core';

import { ConfigService } from '../config.service';
import { ApiService } from '../api.service';

import { Observable, tap, throwError } from 'rxjs';
import { StorageMap } from '@ngx-pwa/local-storage';
import { Trip } from './trip';
import {LocalTripService} from './local-trips.service'
import { HttpParams } from '@angular/common/http';
import { CustomHttpParamEncoder } from '../utility/custom-http-param-encoder';


@Injectable({
  providedIn: 'root'
})
export class TripService {
  private dataApiUrl: string;

  constructor(
    private apiService: ApiService,
    private configService: ConfigService,
    private storage: StorageMap,
    private localTripService: LocalTripService
  ) {
    this.dataApiUrl = this.configService.get('DATA_API_URL');
  }

  getTrips(): Observable<Trip[]> {
    

    const params = new HttpParams().set("filter", JSON.stringify(
      {
        fields: {
          id: true,
          groupName: true,
          location: true,
          tripDate: true
        },
        order: ["tripDate"]
    }
    ));
 
    return this.apiService.callApi<Trip[]>(`${this.dataApiUrl}/staff/my/open-trips`, "GET", params).pipe(tap(
      {
        next: (trips) => {
          for (const trip of trips) {
            trip.tripDate = trip.tripDate.replace("Z", "")  //strip time zone info so it falls back to local time.
          }
        },
        error: (err) => throwError(err)
      }
    ));
    
  }

  getTrip(tripId: number) {
    return this.apiService.callApi<Trip>(`${this.dataApiUrl}/trips/${tripId}`)
  }

  addSelTrip(trip: Trip){
    this.storage.set('sel_trip', trip).subscribe(() => {}, () => {});
  }
  getSelTrip(): Observable<Trip>{
    return this.storage.get("sel_trip") as Observable<Trip>;
  }
}
