import { AfterContentInit, AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import SignaturePad from 'signature_pad';
import { Context } from 'vm';
import {Options, PointGroup } from 'signature_pad'

export interface Point {
  x: number;
  y: number;
  time: number;
}



@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss']
})
export class SignaturePadComponent implements  AfterViewInit {

  @Input() public options: Options;
  @Input() public width: number;
  @Input() public height: number;
  @Output() public beginSignature: EventEmitter<boolean>;
  @Output() public endSignature: EventEmitter<boolean>;

  @ViewChild('signatureCanvas')  canvas: ElementRef<HTMLCanvasElement>;

  private signaturePad: SignaturePad;


  constructor() {

    this.options = this.options || {};
    this.beginSignature = new EventEmitter<boolean>();
    this.endSignature = new EventEmitter<boolean>();
  }
  ngAfterViewInit(): void {

    this.signaturePad = new SignaturePad(this.canvas.nativeElement, this.options);
    this.signaturePad.onBegin = this.onBegin.bind(this);
    this.signaturePad.onEnd = this.onEnd.bind(this);
  }



  public toData(): PointGroup[] {
    return this.signaturePad.toData();
  }

  public toDataURL(imageType?: string, quality?: number): string {
    return this.signaturePad.toDataURL(imageType, quality); // save image as data URL
  }

  public clear(): void {
    this.signaturePad.clear();
  }

  public isEmpty(): boolean {
    return this.signaturePad.isEmpty();
  }

  // Unbinds all event handlers
  public off(): void {
    this.signaturePad.off();
  }

  // Rebinds all event handlers
  public on(): void {
    this.signaturePad.on();
  }

  // notify subscribers on signature begin
  private onBegin(): void {
    this.beginSignature.emit(true);
  }

  // notify subscribers on signature end
  private onEnd(): void {
    this.endSignature.emit(true);
  }
}
