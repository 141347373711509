
    <h1 mat-dialog-title>Some Participants are not Selected</h1>
    <div mat-dialog-content fxLayout="column"> 
        <p fxFlex="100" >You have not selected all of the participants indicated as coming on the trip. </p>
        <p fxFlex="100" >Select the participants that need to be permanently removed from the roster.</p>
        <form fxFlex="33">
        <mat-selection-list name="removeParticipants" [(ngModel)]="selectedParticipants">
                <mat-list-option *ngFor="let participant of participants" [value]="participant" checkboxPosition="before">{{participant.name}}</mat-list-option>
            </mat-selection-list>
        </form>    
    </div>
    <div mat-dialog-actions>
        <button  mat-flat-button [mat-dialog-close]="getDialogResult(true)" color="primary" ><span *ngIf="hasSelectedParticipants()">Remove and </span>Continue</button>
        <button mat-stroked-button  [mat-dialog-close]="getDialogResult(false)" >Cancel</button>
    </div>
