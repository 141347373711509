import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs';
import { Observable, switchMap } from 'rxjs';
import { LocalHeadCount } from 'src/app/db/local-head-count';
import { Participant } from 'src/app/participants/participants';
import { ParticipantsService } from 'src/app/participants/participants.service';
import { Trip } from 'src/app/trip/trip';
import { TripService } from 'src/app/trip/trip.service';
import { HeadCount, HeadCountMember } from '../head-count';
import { HeadCountsService } from '../head-counts.service';

interface HeadCountViewModel {
  name: string
}

@Component({
  selector: 'app-head-count',
  templateUrl: './head-count.component.html',
  styleUrls: ['./head-count.component.scss']
})
export class HeadCountComponent implements OnInit {

  constructor(
    private tripService: TripService,
    private participantService: ParticipantsService,
    private headCountService: HeadCountsService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar
  ) {

    const nav = this.router.getCurrentNavigation();
    if (nav?.extras?.state?.localCount) {
      this.headCount = nav.extras.state.localCount;
    }

  }




  trip: Trip;
  headCount: HeadCount | LocalHeadCount;
  participants: Participant[];


  headCountDataSource = new MatTableDataSource<HeadCountViewModel>([]);
  @ViewChild(MatTable) headCountTable: MatTable<HeadCount>;
  columnsToDisplay = ["name"]

  ngOnInit(): void {

    //if headcount already exists, then we passed a local count through state
    if (!this.headCount) {
      

      const countId = Number.parseInt(this.route.snapshot.paramMap.get("countId"));

      this.headCountService.getCountById(countId).pipe(
        switchMap(headCount => this.tripService.getTrip(headCount.tripId).pipe(
          switchMap(trip => this.participantService.getParticipants(trip.id).pipe(
            map(participants => ({ headCount, trip, participants }))
          ))
        ))
      ).subscribe(({ headCount, trip, participants }) => {
        this.headCount = headCount;
        this.trip = trip;
        this.participants = participants;

        for (const countMember of this.headCount.headCountMembers) {
          const participant = participants.find((p) => p.newtripUserId === countMember.newtripUserId)
          this.headCountDataSource.data = [...this.headCountDataSource.data, {
            name: participant?.firstname + " " + participant?.middlename + " " + participant?.lastname
          }]
        }

        if (this.headCountTable) {
          this.headCountTable.renderRows();
        }
      })


    }
  }

  

}
