<div id="orientation-warning">
  <h2>Please orient your device horizontally or expand your browser window</h2>
</div>
<div id="signature-container">
  <h4 *ngIf="title" mat-dialog-title>{{title}}</h4>
  <div mat-dialog-content>
    <app-signature-pad #sigPad [width]="568" [height]="180"></app-signature-pad>
    <p>{{prompt}}</p>
  </div>
  <div mat-dialog-actions>
    <button [disabled]="signatureIsEmpty()" mat-flat-button [mat-dialog-close]="signature" color="primary">Save</button>
    <button mat-stroked-button (click)="clearSignature()">Clear</button>
  </div>
</div>