import { Component, Inject, Input, OnInit } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface RemoveParticipantData {
  name: string,
  id: number
}
export interface ParticipantRemovalDialogData {
  removalCandidates: RemoveParticipantData[]
}

export interface ParticipantRemovalDialogResult {
  confirmed: boolean,
  participantsToRemove: RemoveParticipantData[]
}

@Component({
  selector: 'app-participant-removal-dialog',
  templateUrl: './participant-removal-dialog.component.html',
  styleUrls: ['./participant-removal-dialog.component.scss']
})
export class ParticipantRemovalDialogComponent{

  constructor(@Inject(MAT_DIALOG_DATA) public data: ParticipantRemovalDialogData) { 
    this.participants = data.removalCandidates
  }

  @Input()
  participants: RemoveParticipantData[];

  selectedParticipants: RemoveParticipantData[];

  getDialogResult(confirmed: boolean): ParticipantRemovalDialogResult {
    return {
      confirmed: confirmed,
      participantsToRemove: this.selectedParticipants,

    }
  }

  hasSelectedParticipants() {
    return this.selectedParticipants && this.selectedParticipants.length > 0
  }

}
