import { Injectable } from '@angular/core';

import { ConfigService } from '../config.service';
import { ApiService } from '../api.service';

import { Observable } from 'rxjs';
import { StorageMap } from '@ngx-pwa/local-storage';
import { isArray } from 'util';
import { Participant } from './participants';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ParticipantsService {
  private dataApiUrl: string;

  constructor(
    private apiService: ApiService,
    private configService: ConfigService,
    private storage: StorageMap,
  ) {
    this.dataApiUrl = this.configService.get('DATA_API_URL');
  }

  getParticipants(tripId: number): Observable<Participant[]> {
    

    const params = new HttpParams().set("filter", JSON.stringify(
      {
        order: ["lastname", "firstname"]
    }
    ));

    return this.apiService.callApi<Participant[]>(`${this.dataApiUrl}/trips/${tripId}/participants`, "GET", params);
  }
  syncDatas(surveys): Observable<any> {
    

    return this.apiService.callApi(`${this.dataApiUrl}/survey/sync`,"POST",{ surveyResultsArray : JSON.stringify(surveys) });
  }

  addSelParticipants(participant){
    this.storage.set('sel_participant', participant).subscribe({next: () => {}, error: () => {}});
  }
  addSelDate(date){
    this.storage.set('sel_date', date).subscribe({next: () => {}, error: () => {}});
  }
  getSelDate(){
    return this.storage.get("sel_date");
  }
  getSelParticipants(){
    return this.storage.get("sel_participant");
  }
  getSurveys(){
    return this.storage.get("surveys");
  }
  updateSurveys(surveys){
    this.storage.get('surveys', surveys).subscribe({next: () => {}, error: () => {}});
  }
  resetSurveys(surveys){
    surveys.forEach(element => {
      element.isSynced = 1;
    });
    this.storage.get('surveys', surveys).subscribe({next: () => {}, error: () => {}});
  }
}
