import { DatabaseService } from "../db/database.service";
import Dexie from "dexie";
import { Injectable } from "@angular/core";
import { HeadCount } from "../head-counts/head-count";
import { LocalHeadCount } from "./local-head-count";
import { HeadCountsService } from "../head-counts/head-counts.service";
import { BehaviorSubject, first, firstValueFrom, Subject } from "rxjs";






@Injectable()
export class LocalHeadCountService {
    table: Dexie.Table<LocalHeadCount, number>;

    private localHeadCountChange$: BehaviorSubject<void>;

    constructor(private databaseService: DatabaseService,
        private headCountService: HeadCountsService) {
        this.table = this.databaseService.table('headCounts');
        this.localHeadCountChange$ = new BehaviorSubject(null);

    }

    public get changed$() {
        return this.localHeadCountChange$;
    }


    getAll() {
        return this.table.toArray();
    }

    getCount() {
        return this.table.count();
    }

    async add(count: LocalHeadCount) {
        return this.table.add(count).then((newId) => {
            this.triggerCountUpdate()
            return newId
        });
    }

    triggerCountUpdate() {
        console.log("Triggering head count change update");
        this.localHeadCountChange$.next();
    }

    update(id: number, changes: Partial<LocalHeadCount>) {
        return this.table.update(id, changes);
    }

    async remove(id: number) {
        return this.table.delete(id).then(() => {
            this.triggerCountUpdate()
        });
    }

    put(count: LocalHeadCount) {
        return this.table.put(count)
    }

    async synchronize() {

        const countsPending = (await this.getAll()).sort((a, b) => a.id - b.id);

        let uploadCount = 0;
        
        for (const newCount of countsPending) {
            
            try {
                await firstValueFrom(this.headCountService.newCount(newCount));
                console.log(`Removing Count: ${JSON.stringify(newCount)}`)
                await this.remove(newCount.id)
                uploadCount++;
            }
            catch (err) {

                console.error(err);
                if (err.error) {
                    console.log("Error synchronizing: " + JSON.stringify(err.error));
                }
            }

        }

        if (countsPending.length !== uploadCount) {
            throw new Error("There was a problem uploading counts.  All counts may not have been uploaded.")
        }

        return uploadCount;
    }

}