import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationComponent,ConfrimSyncDialogComponent } from './navigation/navigation.component';
import { MaterialModule } from './material.module';


import { LoginComponent } from './login/login.component';

import { ParticipantsComponent } from './participants/participants.component';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {LocalAuthService } from './auth/local-auth.service';
import {AuthGuard } from './auth/auth-guard.service';
import {AccessGuard } from './auth/access-guard.service';

import {TripService } from './trip/trip.service';
import {ParticipantsService } from './participants/participants.service';

import { JwtInterceptor } from "./auth/token.interceptor"
import { NgxUiLoaderModule } from 'ngx-ui-loader';

import { PwaService } from "./pwa.service"
import { DatabaseService } from './db/database.service';
import { LocalTripService } from './trip/local-trips.service';
import { LocalParticipantService } from './participants/local-participants.service';
import { HeadCountsComponent } from './head-counts/head-counts.component';
import { TripSelectComponent } from './trip-select/trip-select.component';
import { HeadCountComponent } from './head-counts/head-count/head-count.component';
import { NewHeadCountComponent } from './head-counts/new-head-count/new-head-count.component';
import { LocalHeadCountService } from './db/local-head-count.service';
import { NetworkInterceptor } from './utility/network-interceptor';
import { LoadingService } from './utility/loading.service';
import { SignaturePadComponent } from './signature-pad/signature-pad.component';
import { SignaturePadDialogComponent } from './signature-pad/signature-pad-dialog.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ParticipantRemovalDialogComponent } from './head-counts/new-head-count/participant-removal-dialog/participant-removal-dialog.component';
import { TrackJsErrorHandler } from './utility/trackjs.handler';
import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import {
  GoogleLoginProvider,
} from '@abacritt/angularx-social-login';
import { GoogleSigninButtonDirective } from './google-signin-button.directive';

@NgModule({
    declarations: [
        NavigationComponent,
        ConfrimSyncDialogComponent,
        LoginComponent,
        ParticipantsComponent,
        HeadCountsComponent,
        TripSelectComponent,
        HeadCountComponent,
        NewHeadCountComponent,
        SignaturePadComponent,
        SignaturePadDialogComponent,
        ParticipantRemovalDialogComponent,
        GoogleSigninButtonDirective,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialModule,
        HttpClientModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: "registerImmediately" }),
        FormsModule,
        ReactiveFormsModule,
        NgxUiLoaderModule,
        FlexLayoutModule,
        SocialLoginModule
    ],
    bootstrap: [NavigationComponent],
    providers: [
        AuthGuard,
        AccessGuard,
        LocalAuthService,
        TripService,
        ParticipantsService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NetworkInterceptor,
            multi: true
        },
        PwaService,
        DatabaseService,
        LocalTripService,
        LocalParticipantService,
        LocalHeadCountService,
        LoadingService,
        {
            provide: ErrorHandler, useClass: TrackJsErrorHandler
        },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
              autoLogin: false,
              providers: [
                {
                  id: GoogleLoginProvider.PROVIDER_ID,
                  provider: new GoogleLoginProvider(
                    environment.config.GOOGLE_SIGNIN_CLIENT_ID,
                    {
                      oneTapEnabled: false
                    }
                  )
                },
              ],
              onError: (err) => {
                console.error(err);
              }
            } as SocialAuthServiceConfig,
          }
    ]
})
export class AppModule { }
