import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Observable, Subject, tap } from 'rxjs';
import * as _ from "lodash";
import { StorageMap } from '@ngx-pwa/local-storage';
import { ConfigService } from '../config.service';

import jwt_decode from 'jwt-decode'
import { MatSnackBar } from '@angular/material/snack-bar';



export interface User {
  id: string,
  name: string,
  exp: string,
  iat: string,
  newstaffId: number,
  roles: string[]
}

export interface TokenObject {
  accessToken: string,
  refreshToken: string
}

export interface RefreshToken {
  token: string,
  iat: string,
  exp: string,
  iss: string
}

export interface RefreshResponse {
  accessToken: string;
  refreshToken: string;
}

export type LoginTypes = 'local' | 'google'
export interface LoginParameters {
  username?: string;
  password?: string;
  token?: string;
  type: LoginTypes;
}

@Injectable({
  providedIn: 'root'
})
export class LocalAuthService {
  private announceLoggedIn = new Subject<any>();

  loggedIn$ = this.announceLoggedIn.asObservable();

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  private dataApiUrl: string;

  response: any;
  constructor(
    private router: Router,
    private http: HttpClient,
    private localStorage: StorageMap,
    private configService: ConfigService,
    private snackBar: MatSnackBar
    ) {
    this.dataApiUrl = this.configService.get('DATA_API_URL');
  }


  get currentUser(): User {
    const token = localStorage.getItem("token")
    let currentUser: User;
    if (token) {
      currentUser = jwt_decode(token)
    }

    return currentUser;

  }

  get authToken(): string {
    return localStorage.getItem("token");
  }

  get refreshToken(): RefreshToken {
    const token = localStorage.getItem("refreshToken");
    let refreshToken: RefreshToken;
    if (token) {
      refreshToken = jwt_decode(token)
    }

    return refreshToken;
  }

  get loggedIn(): boolean {
    return this.refreshToken && new Date(Number.parseInt(this.refreshToken.exp) * 1000) > new Date()
  }

  login(params: LoginParameters) {

    let loginObservable: Observable<RefreshResponse>

    if (params.type === 'local') {
      const loginData = { email: params.username, password: params.password }
       loginObservable =   this.http.post<RefreshResponse>(`${this.dataApiUrl}/users/refresh-login`, loginData)
    }
    else if (params.type === 'google') {
       loginObservable = this.http.post<RefreshResponse>(`${this.dataApiUrl}/users/google-oauth`, {credential: params.token})
    }


    let self = this;
  
    loginObservable.subscribe({
      next: (result) => {
        if (result) {
          self.setToken(result);
          self.router.navigate(["/counts"]);
         self.snackBar.open("Login succeed.", null, {
            duration: 4000
          });
        } else {
          self.snackBar.open("Cannot login. Please try again later.", null, {
            duration: 4000
          });
        }
      }
    ,
    error: (err) => {
      if (err.status === 401 || err.status === 422) {
        let message = "Invalid username or password.  Check your information and try again"
        
        if (params.type === 'google') {
          message = "Invalid Google login.  Check your information and try again"
        }

        self.snackBar.open(message, null, {
          duration: 8000
        });
      }
      else {
        self.snackBar.open("Unexpected error. Please try again later.", null, {
          duration: 4000
        });
      }

    }
  })
  }



  refreshLogin() {

    const refreshToken = localStorage.getItem("refreshToken");
    console.log("Refreshing login");
    return this.http.post<RefreshResponse>(`${this.dataApiUrl}/users/refresh`,  {refreshToken: refreshToken})
   
  }

  logout() {
    this.clearToken();
    setTimeout(() => {
    }, 300)
  }

  setToken(tokenObject: Partial<TokenObject>) {
   if (tokenObject.accessToken){
    localStorage.setItem('token', tokenObject.accessToken);
   }

   if (tokenObject.refreshToken) {
    localStorage.setItem('refreshToken', tokenObject.refreshToken);
   }
  }

  getToken() {
    return localStorage.getItem('token');
  }

  clearToken() {

    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.clear();
  }
}
