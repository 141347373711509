import { DatabaseService } from "../db/database.service";
import Dexie from "dexie";
import { Injectable } from "@angular/core";
import { Participant } from "./participants";






@Injectable()
export class LocalParticipantService {
    table: Dexie.Table<Participant, number>;

    constructor (private databaseService: DatabaseService) {
        this.table = this.databaseService.table('participants');
    }

    getAll() {
        return this.table.toArray()
    }

    add(participant: Participant) {
       return  this.table.add(participant);
    }

    update(id: number, changes: Partial<Participant>) {
        return this.table.update(id, changes);
    }

    remove(id: number) {
        return this.table.delete(id);
    }

    put(participant: Participant) {
        return this.table.put(participant)
    }

}