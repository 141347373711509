<mat-form-field>

  <mat-label *ngIf="hasTrips()">Select Trip</mat-label>
  <mat-label *ngIf="!hasTrips()">No Trips Found</mat-label>
        <mat-select [value]="selectedTrip">
          <mat-option *ngFor="let trip of trips" [value]="trip" (onSelectionChange)="tripSelectionChanged($event)">
            {{ trip.tripDate | date }} - {{trip.location}} - {{trip.groupName}}
          </mat-option>
        </mat-select>
</mat-form-field>

