import { Component, Injectable, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table'
import {MatPaginator} from '@angular/material/paginator'
import { Network } from '@ngx-pwa/offline';
import { Observable } from 'rxjs';
import { Participant } from "./participants";
import { ParticipantsService } from "./participants.service";
import { MatSnackBar } from '@angular/material/snack-bar';

import { NativeDateAdapter, DateAdapter,MAT_DATE_FORMATS } from '@angular/material/core';
import { formatDate } from '@angular/common';
import {UntypedFormControl} from '@angular/forms';
import {MatDatepickerInputEvent} from '@angular/material/datepicker';
import { TripService } from "../trip/trip.service";
import { Trip } from '../trip/trip';

export const PICK_FORMATS = {
  parse: {dateInput: {month: 'short', year: 'numeric', day: 'numeric'}},
  display: {
      dateInput: 'input',
      monthYearLabel: {year: 'numeric', month: 'short'},
      dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
      monthYearA11yLabel: {year: 'numeric', month: 'long'}
  }
};
@Injectable()
export class PickDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
      if (displayFormat === 'input') {
          return formatDate(date,'MM/dd/yyyy',this.locale);;
      } else {
          return date.toDateString();
      }
  }
}

@Component({
  selector: 'app-participants',
  templateUrl: './participants.component.html',
  styleUrls: ['./participants.component.scss'],
  providers: [
    {provide: DateAdapter, useClass: PickDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: PICK_FORMATS}
    ]
})

export class ParticipantsComponent implements OnInit {

  
  checked = true;
  participants: Participant[] = [];
  displayedColumns: string[] = ['name', 'screen', 'action'];
  dataSource = new MatTableDataSource<Participant>(this.participants);

  participants$: Observable<Participant[]>;
  isInteractionStarted: boolean = false;
  sel_participant = "";
  loading :boolean =  true;

  date = new UntypedFormControl(new Date());
  selectedDate = "";
  surveys = [];
  selectedTripId: number ;
  selectedTrip: Trip;

  @ViewChild(MatSort, {static: false}) sort: MatSort;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    // private authService: AuthService
    protected network: Network,
    private participantsService: ParticipantsService,
    private snackBar: MatSnackBar,
    private tripService: TripService,
  ) {

  }

  ngOnInit() {
    this.route.queryParams

    //TODO: Handle if it doesn't parse to a number or isn't found
    this.selectedTripId = Number.parseInt(this.route.snapshot.paramMap.get("tripid"));

    this.tripService.getTrip(this.selectedTripId).subscribe((trip) => this.selectedTrip = trip);


    this.participants$ = this.participantsService.getParticipants(this.selectedTripId);
    let self = this;
    this.selectedDate = this.getDateString(new Date());
    this.participants$.subscribe({ 
      next(values) {
        self.loading = false;
        self.participants = values;
        self.loadTable();
      } 
    })
    //TODO: Get participant check-in status here?
    //this.getSurveys();
  }
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  loadTable(){
    this.dataSource =  new MatTableDataSource<Participant>(this.participants);
    this.dataSource.sort = this.sort;
    
  }
  getFullname(element: Participant){
    return element.firstname + " " + element.middlename + " " + element.lastname;
  }

  getDateString(newDate){
    return ('0' + (newDate.getMonth()+1)).slice(-2) + '/' + ('0' + newDate.getDate()).slice(-2) + '/' + newDate.getFullYear();
  }
  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    let newDate = new Date(event.value);
    this.selectedDate = this.getDateString(newDate)
  }

  onSurveyClicked(element){
    this.participantsService.addSelParticipants(element)
    this.participantsService.addSelDate(this.selectedDate)
    this.router.navigate(["/survey"]);
  }

  getSurveys(){
    this.participantsService.getSurveys().subscribe(
      (data: any) => {
        if(data){
          this.surveys = data;
          console.log("surveys",this.surveys)
        }
        else
          this.surveys = [];
      }
    )
  }
  checkSurveyStatus(user){
    let existing = this.surveys.filter((item) => {
      return item['userID'] == user['userID'] && item["timestamp"] == this.selectedDate && item["tripID"] == this.selectedTripId
    })
    if(existing.length > 0){
      return true;
    }else{
      return false;
    }
  }
}
