import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SignaturePadComponent } from './signature-pad.component';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface SignatureDialogResult {
  isValid: boolean,
  dataUrl: string
}

export interface SignatureDialogData {
  title?: string;
  prompt?: string;
}

@Component({
  selector: 'app-signature-pad-dialog',
  templateUrl: "./signature-pad-dialog.component.html",
  styleUrls: ['./signature-pad-dialog.component.scss']
})
export class SignaturePadDialogComponent {

  @ViewChild('sigPad') sigPad: SignaturePadComponent;
  title: string;
  prompt: string;

  
  get signature(): SignatureDialogResult {
    if (this.sigPad && !this.sigPad.isEmpty()) {
      return {isValid: true, dataUrl: this.sigPad.toDataURL()};
    }
    else {
      return {isValid: false, dataUrl: ""}
    }

  }

  signatureIsEmpty() {
    if (this.sigPad) {
      return this.sigPad.isEmpty();
    }
    else {
      return true
    }

  }

  clearSignature() {
    this.sigPad.clear();
  }

  @Output() signatureSaved: EventEmitter<String>
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SignatureDialogData
  ) {
    this.signatureSaved = new EventEmitter<String>();
    if (data && data.title) {
      this.title = data.title;
    }
    if(data && data.prompt) {
      this.prompt = data.prompt;
    }
  }

}
