<mat-card appearance="outlined">
    <mat-card-title>
        Count on {{headCount?.countDate | date}}
    </mat-card-title>
  <mat-card-content>
    
    <table mat-table [dataSource]="headCountDataSource">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let headCountMember" > {{headCountMember.name}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
    </table>

  </mat-card-content>
</mat-card>
