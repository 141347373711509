import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from 'selenium-webdriver/http';
import { LocalAuthService } from './local-auth.service';
 
@Injectable()
export class AuthGuard  {
 
    constructor(
        private router: Router,
        private authService: LocalAuthService
    ) { }
 
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authService.loggedIn) {
            TrackJS.configure({userId: this.authService.currentUser.id})
            // logged in so return true
            return true;
        }
 
        // not logged in so redirect to login page
        TrackJS.configure({userId: ""});
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}
