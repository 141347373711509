import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, from, map, Observable, of, switchMap, tap } from 'rxjs';
import { LocalHeadCount } from '../db/local-head-count';
import { LocalHeadCountService } from '../db/local-head-count.service';
import { ParticipantsService } from '../participants/participants.service';
import { Trip } from '../trip/trip';
import { TripService } from '../trip/trip.service';
import { HeadCount } from './head-count';
import { HeadCountsService } from './head-counts.service';

@Component({
  selector: 'app-head-counts',
  templateUrl: './head-counts.component.html',
  styleUrls: ['./head-counts.component.scss']
})
export class HeadCountsComponent implements OnInit {

  selectedTrip: Trip;
  trips$: Observable<Trip[]>;
  @ViewChild(MatTable) headCountTable: MatTable<HeadCount | LocalHeadCount>;

  headCountDataSource = new MatTableDataSource<HeadCount | LocalHeadCount>()
  columnsToDisplay = ["countDate", "count"];

  navigationMessage: string;

  constructor(private headCountsService: HeadCountsService,
    private tripService: TripService,
    private router: Router,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private localHeadCountService: LocalHeadCountService,
    private participantsService: ParticipantsService
  ) {
    const nav = this.router.getCurrentNavigation();

    if (nav?.extras?.state?.message) {
      this.navigationMessage = nav.extras.state.message;
    }

  }
  ngOnInit(): void {


    //techincally displays on a back/forward nav due to message storage in state.  Maybe should move to a service?
    if (this.navigationMessage) {
      console.log(this.navigationMessage)
      this.snackBar.open(this.navigationMessage, "Close", { duration: 10000 });
      this.navigationMessage = "";
    }

    this.refreshTrips();

    const tripIdParam = this.route.snapshot.paramMap.get("tripId")

   

  }

  onTripSelected(trip: Trip) {

    this.selectedTrip = trip;
    this.headCountsService.getCountsForTrip(trip.id).pipe(
      switchMap(headCounts => from(this.localHeadCountService.getAll()).pipe(
        map(localHeadCounts => {
          return [...headCounts, ...localHeadCounts]
        })
      )
      )
    ).subscribe(
      (headCounts) => {
        this.headCountDataSource.data = headCounts;
        if (this.headCountTable) {
          this.headCountTable.renderRows();
        }
      }
    )
  }

  onCountClicked(count: HeadCount | LocalHeadCount) {
    //remove functionality for initial release.  Add selectable-table class in the future to table for row hover styling 

    // if (!Object.hasOwnProperty.call(count, ["isSigned"])) {
    //   this.router.navigate(["count"], { state: { localCount: count } })
    // }
    // else {
    //   this.router.navigate(["count", count.id]);
    // }
  }

  onNewClicked() {
    this.router.navigate(["trip", this.selectedTrip.id, "new-count"], { state: { headCountCount: this.headCountDataSource.data.length } })
  }

  refreshTrips() {
    this.selectedTrip = null;
    this.headCountDataSource.data = null;

    this.trips$ = this.tripService.getTrips().pipe(
      tap((trips) => this.preLoadCountData(trips))
    ).pipe(
      catchError((error, caught) => {
        console.log("error", error)
        console.log(`getTrips() failed: ${error.status}`);

        this.snackBar.open("Cannot get results from the server or cache", "Close");

        return [];
      })
    );
  }

  hasCounts() {
    return this.headCountDataSource?.data?.length > 0;
  }

  preLoadCountData(trips: Trip[]) {
    for (const trip of trips) {
      this.headCountsService.getCountsForTrip(trip.id).pipe(
        switchMap(headCounts => this.participantsService.getParticipants(trip.id)
        )
      ).subscribe(() => { })
    }
  }

}
