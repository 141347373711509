<mat-card appearance="outlined">
  <mat-card-content>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <mat-error *ngIf="loginInvalid">
        The username and password were not recognised
      </mat-error>
      <mat-form-field class="full-width-input">
        <input type="email" matInput placeholder="Email" formControlName="username" required>
        <mat-error>
          Please provide a valid email address
        </mat-error>
      </mat-form-field><br>
      <mat-form-field class="full-width-input">
        <input matInput type="password" placeholder="Password" formControlName="password" required>
        <mat-error>
          Please provide a valid password
        </mat-error>
      </mat-form-field><br>
      <button mat-stroked-button type="submit" color="primary" class="login">LOGIN</button>
      <!-- Needed to create custom directive to add support for the width parameter.  We can switch back if they ever implement it -->
      <app-google-signin-button type="standard" size="large" [width]="300" ></app-google-signin-button>
    </form>
  </mat-card-content>
</mat-card>